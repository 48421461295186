import { createSelector } from '@reduxjs/toolkit';
import featuresCods from 'constants/features';
import { UserLayer, UserRoles } from 'pages/admin/constant';

export const featureSelector = state => state.feature;

export const hasObservePointFeatureSelector = createSelector(
  state => state.feature?.features || [],
  features => features.find(feature => feature.code === featuresCods.OBSERVE_POINT) !== undefined,
);

export const hasTrustedDestinationSelector = createSelector(
  state => state.feature?.features || [],
  features => features.find(feature => feature.code === featuresCods.TRUSTED_DESTINATIONS) !== undefined,
);

export const hasELinksSelector = createSelector(
  state => state.feature?.features || [],
  features => features.find(feature => feature.code === 'elink') !== undefined,
);

export const isApproveRequiredSelector = createSelector(
  state => state.auth,
  hasTrustedDestinationSelector,
  ({ role, userRoleType }, hasTrustedDestination) =>
    hasTrustedDestination
    && userRoleType === UserLayer.Domain
    && role?.code !== UserRoles.DomainAdmin,
);

export const hasPowerPagesSelector = createSelector(
  state => state.feature?.features || [],
  features => features.find(feature => feature.code === featuresCods.POWER_PAGES) !== undefined,
);
