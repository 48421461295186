import moment from 'moment/moment';
import { CalendarOutlined } from '@ant-design/icons';

export const FilterOperatorOption = [
  { value: 'and', name: 'And' },
  { value: 'or', name: 'Or' },
];

export const optionsByDateValues = {
  today: moment().format('YYYY-MM-DD'),
  yesterday: moment().clone()
    .subtract(1, 'days')
    .format('YYYY-MM-DD'),
  '7_days': moment().clone()
    .subtract(7, 'days')
    .format('YYYY-MM-DD'),
  '30_days': moment().clone()
    .subtract(30, 'days')
    .format('YYYY-MM-DD'),
  month: moment().clone()
    .startOf('month')
    .format('YYYY-MM-DD'),
};

export const dateRangeSelectOptions = [
  { value: 'today', name: 'Today' },
  { value: 'yesterday', name: 'Yesterday' },
  { value: '7_days', name: 'Last 7 days' },
  { value: '30_days', name: 'Last 30 days' },
  { value: 'month', name: 'This Month' },
  { value: 'custom', name: 'Custom', icon: <CalendarOutlined style={{ marginRight: '4px' }} /> },
];

export const schemaFilterItem = {
  filterLink: '',
  attributes: '',
  operator: '',
  values: '',
};

export const OPTIONS_FOR_DATE_VALUES = {
  today: 'today',
  yesterday: 'yesterday',
  week: '7_days',
  thirtyDays: '30_days',
  month: 'month',
};
