import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import { Navigate } from 'react-router';
import loadable from 'components/Loadable';
import AuthGuard from 'middleware/AuthGuard';

const LinkAnalyticPage = loadable(lazy(() => import('pages/LinkAnalytic/LinkAnalyticPage')));
const DashboardDefault = loadable(lazy(() => import('pages/dashboard/default')));
const AnalyticsPage = loadable(lazy(() => import('pages/analytics/analyticsPage')));
const Overview = loadable(
  lazy(() => import('layout/MainLayout/Analytics/includes/AnalyticTabs/components/Overview')),
);
const Device = loadable(
  lazy(() => import('layout/MainLayout/Analytics/includes/AnalyticTabs/components/Device')),
);
const TagsAndReferrers = loadable(
  lazy(() =>
    import('layout/MainLayout/Analytics/includes/AnalyticTabs/components/TagsAndReferrers'),
  ),
);
const Location = loadable(
  lazy(() => import('layout/MainLayout/Analytics/includes/AnalyticTabs/components/Location')),
);
const MaintenanceError = loadable(lazy(() => import('pages/maintenance/404')));
const UserProfile = loadable(lazy(() => import('pages/apps/profiles/user')));
const UserTabPersonal = loadable(lazy(() => import('sections/apps/profiles/user/TabPersonal')));
const UserTabDomainInformation = loadable(
  lazy(() => import('sections/apps/profiles/user/DomainInformation')),
);
const AccountTabPassword = loadable(
  lazy(() => import('sections/apps/profiles/account/TabPassword')),
);
const GroupsPage = loadable(lazy(() => import('pages/Groups')));


const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <Navigate to="/links" />,
        },
        {
          path: 'account',
          element: <UserProfile />,
          children: [
            {
              path: '',
              element: <UserTabPersonal />,
            },
            {
              path: 'domain',
              element: <UserTabDomainInformation />,
            },
            {
              path: 'password',
              element: <AccountTabPassword />,
            },
          ],
        },
        {
          path: 'analytics',
          element: <AnalyticsPage />,
          children: [
            {
              path: '',
              element: <Overview />,
            },
            {
              path: 'overview',
              element: <Overview />,
            },
            {
              path: 'device',
              element: <Device />,
            },
            {
              path: 'tags_referrers',
              element: <TagsAndReferrers />,
            },
            {
              path: 'location',
              element: <Location />,
            },
          ],
        },
        {
          path: 'groups',
          element: <GroupsPage />,
        },
        {
          path: 'links',
          element: <DashboardDefault />,
        },
        {
          path: '/links/:linkId',
          element: <LinkAnalyticPage />,
        },
      ],
    },
    // catch all //
    {
      path: '*',
      element: <MaintenanceError />,
    },
  ],
};

export default MainRoutes;
